














import { Component, Prop, Vue } from "vue-property-decorator";
@Component({ name: "VMediaPaginator" })
export default class VMediaPaginator extends Vue {
  @Prop(Number) readonly page!: number;
  @Prop(Number) readonly numberOfPages!: number;
}
