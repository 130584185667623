













































import { Component, Prop, Vue } from "vue-property-decorator";

import { MediaModel } from "@/@types/model";
import { ImageTypes } from "@/@types/common";

@Component({ name: "VMediaGridItem" })
export default class VMediaGridItem extends Vue {
  @Prop(String) readonly type!: string;
  @Prop(Object) readonly item!: MediaModel;
  @Prop(Object) readonly selectItem!: MediaModel;
  @Prop(String) readonly imageType!: ImageTypes;

  get mediaSize() {
    return (media: MediaModel) => {
      if (media.imageType === "LOGO") return "md";
      return "sm";
    };
  }

  get isDisabled() {
    return this.type === "short" && this.item.imageType !== this.imageType;
  }
}
