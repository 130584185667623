











































import { Component, Prop, Ref, Vue } from "vue-property-decorator";

import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

type SizeRestrictions = {
  min: { width: number; height: number };
  max: { width: number; height: number };
  aspectRatio: number;
};

type Image = { src: string; type: string };

@Component({ name: "VImageCropper", components: { Cropper } })
export default class VImageCropper extends Vue {
  @Prop({ type: Object }) readonly image!: Image;
  @Prop({ type: Object }) readonly sizeRestrictions!: SizeRestrictions;
  @Prop({ type: Number, default: 1 }) readonly aspectRatio!: number;

  @Ref() readonly cropper!: typeof Cropper;

  isLoading: boolean = true;

  get actions() {
    return {
      false: { text: this.$i18n.t("GlobalUI.Cancel") },
      true: {
        color: "primary",
        text: this.$i18n.t("GlobalUI.Save"),
        handle: () =>
          new Promise((resolve) => {
            const { canvas } = this.cropper.getResult();
            canvas.toBlob((blob: Blob) => {
              resolve(blob);
            }, this.image.type);
          }),
      },
    };
  }

  flip(x: boolean, y: boolean) {
    this.cropper.flip(x, y);
  }

  rotate(angle: number) {
    this.cropper.rotate(angle);
  }
}
