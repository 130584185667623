





























import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: "VMediaUploaderNotice" })
export default class VMediaUploaderNotice extends Vue {
  @Prop(Boolean) readonly value!: boolean;

  handleCancel() {
    this.$emit("input", false);
    this.$emit("cancel");
  }

  handleOk() {
    this.$emit("input", false);
    this.$emit("ok");
  }
}
