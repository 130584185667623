







































import { Component, Prop, Vue } from "vue-property-decorator";

import { MediaModel } from "@/@types/model";
import { ImageTypes } from "@/@types/common";

@Component({ name: "VMediaListItem" })
export default class VMediaListItem extends Vue {
  @Prop(Object) readonly item!: MediaModel;
  @Prop(Object) readonly selectItem!: MediaModel;
  @Prop(String) readonly imageType!: ImageTypes;

  get uploadedBy() {
    if (this.item.isDefault) return this.$t("GlobalUI.SystemAdministrator");
    return this.item.createdBy ? this.item.createdBy : "—";
  }

  get mediaSize() {
    return (media: MediaModel) => {
      if (media.imageType === "LOGO") return "md";
      return "sm";
    };
  }
}
