




































































































































































































import { Component, Prop, Ref, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { Call, Sync } from "vuex-pathify";
import { validationMixin } from "vuelidate";
import * as Validator from "../../services/validators.service";

import VMediaUploaderNotice from "./VMediaUploaderNotice.vue";
import VMediaEditor from "./VMediaEditor.vue";
import VImageCropper from "./VImageCropper.vue";
import VMediaGridItem from "./VMediaGridItem.vue";
import VMediaListItem from "./VMediaListItem.vue";
import VMediaPaginator from "./VMediaPaginator.vue";

import { MEDIA_TYPES, IMAGE_TYPES, IMAGE_RESTRICTIONS } from "../../config";

import { confirm } from "../../helpers/dialogs";
import { MediaModel } from "@/@types/model";
import { ImageTypes, MediaTypes } from "@/@types/common";

const { mimetype, fileSize } = Validator;

type Image = { src: string | null; type: string | null };
type UploadMediaArgs = { file: File; imageType: ImageTypes };

@Component({
  name: "VMediaIterator",
  mixins: [validationMixin],
  components: {
    VMediaUploaderNotice,
    VMediaGridItem,
    VMediaListItem,
    VMediaEditor,
    VMediaPaginator,
  },
})
export default class VMediaIterator extends mixins(validationMixin) {
  @Prop(Object) readonly media!: MediaModel;
  @Prop(String) readonly imageType!: ImageTypes;
  @Prop({ type: String, default: "extends" }) readonly type!: string;
  @Prop({ type: Boolean, default: true }) readonly isEditor!: boolean;

  @Sync("media/mediaList") mediaList!: MediaModel[];
  @Sync("media/selectMedia") selectMedia!: MediaModel | null;
  @Sync("media/isLoading") isLoading!: boolean;
  @Sync("media/isBusy") isBusy!: boolean;

  @Call("media/fetchMedia") fetchMedia!: () => Promise<void>;
  @Call("media/uploadMedia") uploadMedia!: (_obj: UploadMediaArgs) => Promise<void>;
  @Call("media/deleteMedia") deleteMedia!: (_media: MediaModel) => Promise<void>;
  @Call("media/clearMedia") clearMedia!: () => Promise<void>;

  @Ref() refFile!: HTMLInputElement;

  file: File | null = null;
  image: Image = { src: null, type: null };
  selectImageType: ImageTypes | "ALL" = this.imageType ?? "ALL";
  selectMediaType: MediaTypes = "IMAGE";
  selectFilterType: "ALL" | "SHARED" | "OWNER" = "ALL";
  selectViewType: "LIST" | "GRID" = "GRID";

  dialogAgree: boolean = false;
  isMediaEditorDrawer: boolean = false;

  search: string = "";
  page: number = 1;
  perPage: number = 72;

  validations() {
    return {
      file: {
        mimetype: mimetype(["image/jpg", "image/jpeg"], [".jpg", ".jpeg"]),
        fileSize: fileSize(5000),
      },
    };
  }

  @Watch("selectMedia")
  onSelectMediaChanged(media: MediaModel) {
    if (this.isEditor) this.isMediaEditorDrawer = Boolean(media);
  }

  get numberOfPages() {
    return Math.ceil(this.filteredMedia.length / this.perPage);
  }

  get mediaTypes() {
    return MEDIA_TYPES.map((type) => (type.key === "IMAGE" ? type : { ...type, disabled: true }));
  }

  get imageTypes() {
    return [{ key: "ALL", label: "All" }, ...IMAGE_TYPES];
  }

  get filteredMedia() {
    if (this.selectMediaType !== "IMAGE") return [];
    if (this.selectFilterType === "ALL") return this.mediaList;
    if (this.selectFilterType === "SHARED")
      return this.mediaList.filter((photo) => photo.isDefault);
    if (this.selectFilterType === "OWNER")
      return this.mediaList.filter((photo) => !photo.isDefault);

    return [];
  }

  get fileErrors() {
    const errors: string[] = [];
    if (!this.$v.file.$dirty) return errors;
    const { mimetype, fileSize, $params } = this.$v.file;
    !mimetype &&
      errors.push(`The must be a file of type: ${$params.mimetype.value.labels.join(", ")}.`);
    !fileSize && errors.push(`The must be ${$params.fileSize.value} kilobytes.`);

    return errors;
  }

  async created() {
    await this.fetchMedia();
  }

  handleClickNoticeOk() {
    this.dialogAgree = false;
    this.refFile.click();
  }

  async handleUploadMedia(e: Event) {
    this.$v.file.$reset();

    let { files } = e.target as HTMLInputElement;

    if (!files || files.length === 0) {
      this.file = null;
      return;
    }

    const [file] = files;
    this.refFile.value = "";

    this.file = file;
    this.$v.file.$touch();

    if (this.$v.file.$invalid) {
      const [firstError] = this.fileErrors;
      this.$dialog.notify.warning(firstError);
      return;
    }

    const imageType = this.selectImageType !== "ALL" ? this.selectImageType : "IMAGE";

    // Start Image Validation
    const imageErrors = await Validator.image(this.file, imageType);

    if (imageErrors.length) {
      const [firstError] = imageErrors;
      this.$dialog.notify.warning(firstError);
      return;
    }
    // End Image Validation

    const sizeRestrictions = IMAGE_RESTRICTIONS[imageType];
    const aspectRatio = IMAGE_RESTRICTIONS[imageType].aspectRatio || 1;

    // Image Cropper
    if (this.selectMediaType === "IMAGE") {
      const blob = URL.createObjectURL(this.file);
      this.image.src = blob;
      this.image.type = this.file.type;

      const croppedImage = await this.$dialog.showAndWait(VImageCropper, {
        image: this.image,
        sizeRestrictions,
        aspectRatio,
        showClose: false,
      });

      if (!croppedImage) return;

      this.file = new File([croppedImage], this.file.name, {
        type: this.file.type,
        lastModified: this.file.lastModified,
      });
    }

    await this.uploadMedia({ file: this.file, imageType });
  }

  async handleDeleteMedia(media: MediaModel) {
    const result = await this.$dialog.warning(confirm());
    if (!result) return;

    await this.deleteMedia(media);

    if (this.selectMedia && this.selectMedia.id === media.id) this.selectMedia = null;
  }

  // Pagination implementation
  prevPage() {
    if (this.page - 1 >= 1) this.page -= 1;
  }
  nextPage() {
    if (this.page + 1 <= this.numberOfPages) this.page += 1;
  }

  handleSelectMedia(media: MediaModel) {
    this.selectMedia = media;
    this.$emit("select", media);
  }

  handleTransitionEndMediaEditorDrawer() {
    if (!this.isMediaEditorDrawer) this.selectMedia = null;
  }

  destroyed() {
    this.clearMedia();

    if (this.image.src) {
      URL.revokeObjectURL(this.image.src);

      this.image.src = null;
      this.image.type = null;
    }
  }
}
