










































































import { isEqual } from "lodash";
import { Call, Sync } from "vuex-pathify";
import { Component, Ref, Vue, Watch } from "vue-property-decorator";

import { nonReactive } from "@/helpers/functions";

import { MediaModel } from "@/@types/model";

let cacheMedia: MediaModel | null = null;

@Component({ name: "VMediaEditor" })
export default class VMediaEditor extends Vue {
  isBusy: boolean = false;

  isMediaEqualCache: boolean = true;

  @Ref() readonly refId!: HTMLSpanElement;

  @Sync("media/selectMedia") selectMedia!: MediaModel;
  @Sync("media/selectMedia@id") id!: string | number;
  @Sync("media/selectMedia@title") title!: string;

  @Call("media/saveSelectMedia") saveSelectMedia!: () => Promise<void>;

  @Watch("selectMedia")
  onSelectMediaChanged(value: MediaModel, predValue: MediaModel | null) {
    const valueNonReactive = nonReactive(value);
    if (!predValue) cacheMedia = valueNonReactive;
    this.isMediaEqualCache = isEqual(valueNonReactive, cacheMedia);
  }

  get uploadedBy() {
    if (this.selectMedia.isDefault) return this.$t("GlobalUI.SystemAdministrator");
    return this.selectMedia.createdBy ? this.selectMedia.createdBy : "—";
  }

  handleCopyRefId() {
    const textArea = document.createElement("textarea");
    textArea.value = this.refId.textContent as string;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    textArea.remove();

    const range = document.createRange();
    range.selectNodeContents(this.refId);
    const sel = window.getSelection() as Selection;
    sel.removeAllRanges();
    sel.addRange(range);

    this.$dialog.message.info("Copied", { timeout: 1000 });
  }

  async save() {
    this.isBusy = true;
    await this.saveSelectMedia();
    this.isBusy = false;

    this.$emit("media:cancel");
  }
}
